import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import TagsList from "./TagsList"

const Tags = class extends React.Component {

  hideTags = () => {
    const { hideListener } = this.props;
    if (hideListener !== null){
      hideListener()
    }
  }

  render() {
    const { tags, activeTags, onTagClick, isActive } = this.props

    let { group } = tags
    if (group === undefined) {
      group = {
        ...tags,
        totalCount: tags.length,
        fieldValue: "Tags"
      }
    }

    const activeClass = isActive ? "is-active" : ""

    return (
      <div className={`tag-container ${activeClass}`}>
        <div className="tag-header is-hidden-desktop">
          <div className="level">
            <div className="level-left">
              <h4 className="title is-4 level-item">Filters</h4>
            </div>
            <div className="level-right">
              <button type="button" onClick={this.hideTags} className="level-item close button">
                <FontAwesomeIcon icon={["fas", "times"]} title="Close" />
              </button>
            </div>
          </div>
        </div>
        <div className="tag-content">
          {group && group.map((tagGroup, index) => {
            const { edges, fieldValue } = tagGroup
            const key = `${fieldValue}-${index}`
            return (
              <div className="tagGroup" key={key}>
                <h6 className="title is-6">{fieldValue}</h6>
                <TagsList 
                  tags={edges} 
                  activeTags={activeTags} 
                  onTagClick={onTagClick} 
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Tags