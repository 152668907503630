import React from "react"
import { graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { FixedContainer, ColumnsContainer } from "../components/layout/container"
import ChildPage from "../components/layout/page"
import Section from "../components/layout/section"
import PublicationsList from "../components/publications/PublicationsList"
import Tags from "../components/publications/Tags"

const _ = require(`lodash`)

const PublicationsPage = class extends React.Component {
  
  constructor(props) {
    super(props)

    const { data } = this.props
    const { allBibtexArticle, allTagsJson } = data
    const publications = allBibtexArticle.edges

    this.state = {
      tagsVisible: false,
      tags: allTagsJson,
      activeTags: [],
      publications
    }

  }

  filter = (updatedState) => {
    const { data } = this.props
    const { allBibtexArticle } = data
    const publications = allBibtexArticle.edges

    const { activeTags } = updatedState
    const filteredPublications = activeTags.length === 0 ? publications : _.filter(publications, (publication) => {
      const { meta } = publication.node
      const { tags } = meta
      const intersection = _.intersectionWith(tags, activeTags, (tag, slug) => {
        return tag.slug === slug
      })
      return intersection.length === activeTags.length
    })

    this.setState({
      ...updatedState,
      activeTags,
      publications: filteredPublications
    })
  }

  addTag = (tag) => {
    const { activeTags } = this.state
    const tagIndex = activeTags.indexOf(tag)

    if (tagIndex < 0) {
      // tag does not exist in current tag filters
      // add it and filter
      const updatedTags = activeTags.slice()
      updatedTags.splice(0, 0, tag)
      this.updateTags(updatedTags)
    }
  }

  removeTag = (tag) => {
    const { activeTags } = this.state
    const tagIndex = activeTags.indexOf(tag)

    if (tagIndex >= 0) {
      // tag is in our list of filtered tags
      // remove it and filter again
      const filteredTags = activeTags.slice()
      filteredTags.splice(tagIndex, 1)
      this.updateTags(filteredTags)
    }
  }

  updateTags = (selectedTags) => {
    const updatedState = {
      ...this.state,
      activeTags: selectedTags
    }

    this.filter(updatedState)
  }

  hideTags = () => {
    const updatedState = {
      ...this.state,
      tagsVisible: false
    }
    this.setState(updatedState)
  }

  showTags = () => {
    const updatedState = {
      ...this.state,
      tagsVisible: true
    }
    this.setState(updatedState)
  }

  onTagClick = (tag, isSelected) => {
    if (isSelected) {
      this.removeTag(tag)
    } else {
      this.addTag(tag)
    }
  }

  render() {
    const { tags, activeTags, publications, tagsVisible } = this.state
    
    const breadcrumbs = [
      { "key": "home", "title": "Home", "path": "/" },
      { "key": "publications", "title": "Group Publications", "path": "/publications" }
    ]

    const { data } = this.props

    const headerImage = data.header.publicURL

    const parallax = {
      image: headerImage,
      strength: 400
    }
  
    return (
      <ChildPage 
        title="Group Publications"
        breadcrumbs={breadcrumbs}
        parallax={parallax}
        headerSize="is-small"
      >
        <Section>
          <FixedContainer>
            <div className="buttons is-right is-hidden-desktop">
              <button className="show-filters button" onClick={this.showTags} type="button">
                <FontAwesomeIcon icon={["fas", "filter"]} title="Filter" />
                Filter
              </button>
            </div>
            <ColumnsContainer className="is-desktop is-variable is-8">
              <div className="column is-three-quarters-desktop">
                <PublicationsList 
                  publications={publications} 
                  includeMeta
                  onTagClick={this.onTagClick}
                  activeTags={activeTags} 
                />
              </div>
              <div className="column is-one-quarter">
                <Tags 
                  tags={tags} 
                  activeTags={activeTags} 
                  onTagClick={this.onTagClick}
                  isActive={tagsVisible}
                  hideListener={this.hideTags}
                />
              </div>
            </ColumnsContainer>
          </FixedContainer>
        </Section>
      </ChildPage>
    )
  }
}

export default PublicationsPage

export const bibTexQuery = graphql`
  fragment BibTexInformation on BibtexArticle {
    author
    date_added
    doi
    id
    journal
    month
    number
    pages
    title
    url
    volume
    year
    fields {
      citations
      publicationDate
    }
    meta {
      editorHighlight
      remotePdf
      tags {
        title
        slug
        fields {
          publicationCount
        }
      }
      localPdf {
        publicURL
      }
      remoteSuppl
      mediaCoverage {
        id
        title
        site
        url
      }
    }
  }
`

export const pageQuery = graphql`
  query AllPublicationsQuery {
    header: file(relativePath: { eq: "books.jpg" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allBibtexArticle(sort: {fields: fields___publicationDate, order: DESC}) {
      edges {
        node {
          ...BibTexInformation
        }
      }
    }
    allTagsJson {
      group(field: category) {
        totalCount
        fieldValue
        edges {
          node {
            id
            slug
            subcategory
            title
            category
            fields {
              publicationCount
            }
          }
        }
      }
    }
  }
`